<template>
  <div>
    <b-card>
      <div>
        <h4 class="card-title">{{ tabela.quantidadeItens }} registros</h4>
      </div>
      <div
        class="filtro-valorem d-flex align-items-end justify-content-between mb-2"
      >
        <div class="d-flex align-items-center w-100">
          <input-text
            id="input-termo"
            no-label
            :placeholder="$t('LINK_PAGAMENTO.FILTRO')"
            v-model="filtro.termo"
            class="w-50 mr-2"
          />
          <input-date
            id="input-data-inicial"
            no-label
            class="mr-2"
            v-model="filtro.dataInicial"
          />
          <h6 class="mr-2 mt-1">a</h6>
          <input-date
            id="input-data-final"
            no-label
            v-model="filtro.dataFinal"
          />
          <div class="d-flex form-group">
            <b-button class="ml-2" variant="outline-secondary" @click="limpar">
              <i class="fas fa-filter"></i>
              <i class="fas fa-times sub-i"></i>
            </b-button>
            <b-button
              class="ml-2"
              variant="outline-valorem-ciano"
              @click="filtrar"
            >
              <div class="d-flex align-items-center">
                <i class="fas fa-search mr-2"></i>
                <span>Filtrar</span>
              </div>
            </b-button>
            <b-button
              class="ml-2"
              id="btn-novo"
              @click="novo"
              variant="primary"
              v-if="permissao.criar"
            >
              {{ $t("LINK_PAGAMENTO.NOVO") }}
            </b-button>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <b-button
          id="btn-enviar"
          @click="enviar()"
          v-if="dadosSelecionados.length == 1 && permissao.criar"
          class="mr-2"
          variant="outline-primary"
        >
          {{ $t("LINK_PAGAMENTO.REGERAR_LINK") }}
        </b-button>
        <b-button
          id="btn-visualizar"
          @click="editar(null)"
          v-if="dadosSelecionados.length == 1"
          variant="outline-primary"
          class="mr-2"
        >
          {{ $t("LINK_PAGAMENTO.VISUALIZAR") }}
        </b-button>
        <b-button
          id="btn-link-url"
          @click="copiar(null)"
          v-if="dadosSelecionados.length == 1 && dadosSelecionados[0].link"
          variant="primary"
          class="mr-2"
        >
          {{ $t("LINK_PAGAMENTO.COPIAR_LINK") }}
        </b-button>
        <b-button
          id="btn-forcar-consulta"
          @click="forcarConsultaStatus(null)"
          v-if="ehAdmin && podeSolicitarForcarConsultaStatus"
          variant="primary"
          class="mr-2"
        >
          {{ $t("LINK_PAGAMENTO.CONSULTA_STATUS_ESITEF") }}
        </b-button>
        <b-button
          id="btn-consulta-agendamento"
          @click="consultaAgendamento(null)"
          v-if="ehAdmin && podeSolicitarForcarConsultaStatus"
          variant="warning"
        >
          {{ $t("LINK_PAGAMENTO.CONSULTA_AGENDAMENTO") }}
        </b-button>
      </div>
      <valorem-tabela
        id="tabela"
        sem-selecionar-todos
        :metodo-validacao-selecionar="metodoValidacaoSelecionar"
        v-model="tabela.dados"
        :colunas="tabela.colunas"
        :quantidade-itens="tabela.quantidadeItens"
        ref="tabela"
        backend-paginado
        @paginar="listar"
      />
    </b-card>
  </div>
</template>
<script>
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import LinkPagamentoService from "@/common/services/link_pagamento/link_pagamento.service";
import { InputText, InputDate } from "../../components/inputs";
import helpers from "../../common/utils/helpers";
import { SELECIONAR_NUMEROMAXIMOPARCELAS } from "@/store/autenticacao.module";

export default {
  components: {
    InputText,
    InputDate,
  },
  data: function () {
    return {
      filtro: {
        termo: "",
        dataInicial: "",
        dataFinal: "",
      },
      tabela: {
        dados: [],
        colunas: [
          { key: "seletor", label: "" },
          { key: "codigo", label: this.$t("LINK_PAGAMENTO.TABELA.CODIGO") },
          {
            key: "tipoLinkPagamento",
            label: this.$t("LINK_PAGAMENTO.TABELA.TIPO"),
            formatter: (v) => {
              if (v) return helpers.getEnum(v)?.text;
            },
          },
          {
            key: "descricao",
            label: this.$t("LINK_PAGAMENTO.DESCRICAO"),
          },
          {
            key: "clienteEmail",
            label: this.$t("LINK_PAGAMENTO.TABELA.EMAIL"),
          },
          {
            key: "clienteNumeroTelefone",
            label: this.$t("LINK_PAGAMENTO.TABELA.CELULAR"),
            formatter: (v) => {
              if (v) return helpers.formatarTelefone(v);
            },
          },
          {
            key: "valor",
            label: this.$t("LINK_PAGAMENTO.TABELA.VALOR"),
            formatter: (v) => {
              if (v) return helpers.formatarValor(v);
            },
          },
          {
            key: "dataCobranca",
            label: this.$t("LINK_PAGAMENTO.TABELA.DATA_COBRANCA"),
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v);
            },
          },
          {
            key: "dataExpiracao",
            label: this.$t("LINK_PAGAMENTO.TABELA.DATA_EXPIRACAO"),
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v);
            },
          },
          //{ key: "status", label: "Status" },
        ],
        quantidadeItens: 0,
        paginaAtual: 0,
        porPagina: 0,
        url_pagamento: null,
      },
      botaoAtivarInativar: null,
      numeroMaximoParcelas: null,
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("LINK_PAGAMENTO.TITULO_LISTA") },
    ]);
    this.listar();
  },
  methods: {
    filtrar: function () {
      this.$refs.tabela.limpar();
      this.listar();
    },
    limpar: function () {
      this.filtro = {
        termo: "",
        dataInicial: "",
        dataFinal: "",
      };
      this.filtrar();
    },
    listar: function (paginaAtual = 0, porPagina = 10) {
      this.$store.dispatch(START_LOADING);
      LinkPagamentoService.listar(this.filtro, paginaAtual, porPagina)
        .then((res) => {
          var nrParcelas = res.data.informations[0];
          this.numeroMaximoParcelas = nrParcelas;
          this.selecionarNumeroMaximoDeParcelas(nrParcelas);
          this.tabela.dados = res.data.data.itens;
          this.paginaAtual = paginaAtual;
          this.porPagina = porPagina;
          this.tabela.quantidadeItens =
            res.data.data.paginacao.totalDeElementos;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    novo: function () {
      this.$router.push({ name: "link-pagamento-novo" });
    },
    editar: function (id) {
      if (!id) {
        this.$router.push({
          name: "link-pagamento-editar",
          params: { id: this.dadosSelecionados[0].id },
        });
      } else {
        this.$router.push({
          name: "link-pagamento-editar",
          params: { id: id },
        });
      }
    },
    enviar: function () {
      let idSelecionado = this.dadosSelecionados[0].id;

      this.confirmar(
        this.$t(`LINK_PAGAMENTO.TITULO_REGERAR`),
        this.$t(`GERAL.MENSAGEM_CONFIRMAR_ACAO`),
        this.$t(`LINK_PAGAMENTO.REGERAR_LINK`),
        this.$t(`GERAL.CANCELAR`)
      ).then((confirmado) => {
        if (!confirmado) return;

        this.$store.dispatch(START_LOADING);
        LinkPagamentoService.regerar(idSelecionado)
          .then(() => {
            this.toastSucesso(this.$t(`LINK_PAGAMENTO.REGERADO_SUCESSO`));
            this.listar(this.paginaAtual, this.porPagina);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
    forcarConsultaStatus: function () {
      let idSelecionado = this.dadosSelecionados[0].id;

      this.confirmar(
        this.$t("LINK_PAGAMENTO.TITULO_CONSULTA_STATUS_ESITEF"),
        this.$t(`GERAL.MENSAGEM_CONFIRMAR_ACAO`),
        this.$t("LINK_PAGAMENTO.CONSULTA_STATUS_ESITEF"),
        this.$t(`GERAL.CANCELAR`)
      ).then((confirmado) => {
        if (!confirmado) return;

        this.$store.dispatch(START_LOADING);
        LinkPagamentoService.forcarAtualizacaoStatusCobrancaUnica(idSelecionado)
          .then(() => {
            this.toastSucesso("Consulta de status efetuada com sucesso");
            this.listar(this.paginaAtual, this.porPagina);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
    acessar: function () {
      window.open(this.dadosSelecionados[0].link);
    },
    metodoValidacaoSelecionar: function (item) {
      if (this.botaoAtivarInativar == null) return true;
      if (item.ativo != this.botaoAtivarInativar) return false;
      return true;
    },
    copiar: function () {
      var copyTextarea = this.dadosSelecionados[0].link;

      const textArea = document.createElement("textarea");
      textArea.textContent = copyTextarea;
      document.body.append(textArea);
      textArea.select();
      document.execCommand("copy");

      textArea.remove();
    },
    consultaAgendamento: function () {
      let idSelecionado = this.dadosSelecionados[0].id;

      this.confirmar(
        this.$t("LINK_PAGAMENTO.TITULO_CONSULTA_AGENDAMENTO"),
        this.$t(`GERAL.MENSAGEM_CONFIRMAR_ACAO`),
        this.$t("LINK_PAGAMENTO.CONSULTA_AGENDAMENTO"),
        this.$t(`GERAL.CANCELAR`)
      ).then((confirmado) => {
        if (!confirmado) return;

        this.$store.dispatch(START_LOADING);
        LinkPagamentoService.forcarObterConsultaAgendamento(idSelecionado)
          .then(() => {
            this.toastSucesso("Consulta de agendamentos efetuada com sucesso");
            this.listar(this.paginaAtual, this.porPagina);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
    selecionarNumeroMaximoDeParcelas: function (item) {
      this.$store.dispatch(SELECIONAR_NUMEROMAXIMOPARCELAS, item);
    },
  },
  computed: {
    dadosSelecionados: function () {
      return this.tabela.dados.filter((el) => {
        return el.selecionado;
      });
    },
    permissao: function () {
      return helpers.validarAcesso("LinkPagamento");
    },
    podeSolicitarForcarConsultaStatus: function () {
      if (this.dadosSelecionados.length != 1) return false;

      return (
        this.dadosSelecionados.length == 1 &&
        this.dadosSelecionados[0].podeForcarAtualizacaoStatus &&
        this.permissao.editar
      );
    },
    ehAdmin: function () {
      return this.$store.getters.ehAdmin;
    },
    estabelecimentoSelecionado: {
      set: function (value) {
        this.$store.dispatch(SELECIONAR_NUMEROMAXIMOPARCELAS, value);
      },
      get: function () {
        return this.$store.getters["estabelecimentoSelecionado"];
      },
    },
  },
  watch: {
    dadosSelecionados: function () {
      if (this.dadosSelecionados.length == 1) {
        this.botaoAtivarInativar = this.dadosSelecionados[0].ativo;
      } else if (this.dadosSelecionados.length == 0) {
        this.botaoAtivarInativar = null;
      }
    },
    estabelecimentoSelecionado: function () {
      this.listar();
    },
  },
};
</script>

<style scoped>
/* Diversos Dispositivos */
/**Smartphones */
@media screen and (max-width: 480px) {
  #btn-novo {
    width: 100px;
  }
  .d-flex {
    display: flow !important;
  }
}

/**Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  #btn-novo {
    width: 120px;
  }
}

/**Laptops */
@media (min-width: 768px) and (max-width: 1024px) {
  #btn-novo {
    width: 25vw;
  }
}

/**Bigs */
@media (min-width: 1025px) {
  #btn-novo {
    width: 25vw;
  }
}
</style>
