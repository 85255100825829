import ApiService from "@/common/api/api.service";

import helpers from "@/common/utils/helpers";
import cloneDeep from "lodash.clonedeep";

import Store from "../../../store/Store";

const LinkPagamentoService = {
  listar: function (filtro, paginacao = 1, porPagina = 10) {
    let link = `link-pagamento?NumeroDaPagina=${paginacao}&TamanhoDaPagina=${porPagina}`;

    let estabecimentoSelecionado = Store.getters['estabelecimentoSelecionado'];
    link = link.concat(`${estabecimentoSelecionado ? `&EstabelecimentoId=${estabecimentoSelecionado}` : ''}`);
    link = link.concat(`${filtro.termo ? `&TermoPesquisa=${filtro.termo}` : ''}`);
    link = link.concat(`${filtro.dataInicial ? `&DataInicial=${filtro.dataInicial}` : ''}`);
    link = link.concat(`${filtro.dataFinal ? `&DataFinal=${filtro.dataFinal}` : ''}`);

    return ApiService.get(link);
  },
  salvar: function (form) {
    let payloadForm = cloneDeep(form);
    payloadForm.valor = helpers.removerValor(payloadForm.valor || '0');

    if (payloadForm.tipoLinkPagamento == 'AVistaParcelado') {
      if (payloadForm.tipoCobranca == "ValorAvulso") {
        payloadForm.linkPagamentoProdutos = [];
        payloadForm.desconto = 0;
      } else {
        payloadForm.valor = 0;
      }

      if (payloadForm.tipoCliente == "ClienteCadastrado") {
        payloadForm.emailCliente = "";
      } else {
        payloadForm.clienteId = null;
      }
      payloadForm.planoId = null;
    } else {
      payloadForm.descricao = "";
      payloadForm.valor = 0;
      payloadForm.desconto = 0;
      payloadForm.parcelas = 0;
      payloadForm.validade = 0;
      payloadForm.emailCliente = "";
      payloadForm.linkPagamentoProdutos = [];
    }

    if (payloadForm.id) {
      return ApiService.put("link-pagamento", payloadForm);
    }
    return ApiService.post("link-pagamento", payloadForm);
  },
  regerar: function (id) {
    return ApiService.post(`link-pagamento/regerar/${id}`, {});
  },
  forcarAtualizacaoStatusCobrancaUnica: function (id) {
    return ApiService.put(`link-pagamento/forcar-atualizacao-status/${id}`);
  },
  buscar: function (id) {
    return ApiService.get(`link-pagamento/${id}`);
  },
  listarExtratoTransacao: function (filtro, paginacao = 1, porPagina = 10) {
    let link = `link-pagamento/analitico?NumeroDaPagina=${paginacao}&TamanhoDaPagina=${porPagina}`;

    let estabecimentoSelecionado = Store.getters['estabelecimentoSelecionado'];
    link = link.concat(`${estabecimentoSelecionado ? `&EstabelecimentoId=${estabecimentoSelecionado}` : ''}`);

    return ApiService.get(link);
  },
  solicitarCancelamento: function (form) {
    return ApiService.put('link-pagamento/solicitar-cancelamento', form)
  },
  responderSolicitacaoCancelamento: function (form) {
    return ApiService.put('link-pagamento/responder-solicitacao-cancelamento', form)
  },
  buscarSolicitacoes: function (linkPagamentoId) {
    let link = `link-pagamento/solicitacoes?LinkPagamentoId=${linkPagamentoId}`;
    return ApiService.get(link)
  },
  forcarObterConsultaAgendamento: function (id) {
    return ApiService.put(`link-pagamento/obter-consulta-agendamento/${id}`);
  },
};

export default LinkPagamentoService;
